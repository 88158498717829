<script setup lang="ts">
import type { PromoBlock } from '~/types/storyblok/promo-block'
import type { trackBlockClickEmit } from '~/types/track-block-click'
import { getEdgeClasses } from '~/lib/getEdgeClasses'

const props = defineProps<{ blok: PromoBlock }>()
defineEmits<trackBlockClickEmit>()

const forceGridMobile = computed(() => props.blok.cards.length >= 4)
const edgeClass = computed(() => getEdgeClasses(props.blok.edgeStyle))
const noText = computed(() => !!props.blok.cards.find(card => !card.title && !card.description))
</script>

<template>
  <section class="promo-block">
    <h2
      v-if="blok.title"
      class="promo-block__title"
      :class="[edgeClass]"
    >
      {{ blok.title }}
    </h2>
    <div
      v-editable="blok"
      class="promo-block__cards"
      :class="[{ 'promo-block__cards--force-grid-mobile': !!forceGridMobile, 'promo-block__cards--no-text': noText }]"
    >
      <template
        v-for="(item, index) in blok.cards"
        :key="item._uid"
      >
        <component
          :is="item.component"
          :blok="item"
          :edge-style="blok.edgeStyle"
          :index="index"
          :force-grid-mobile="forceGridMobile"
          :no-text="noText"
          @track-block-click="(url: string, clickType: string) => $emit('trackBlockClick', url, clickType)"
        />
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.promo-block {
  @import 'assets/scss/rules/breakpoints';
  @import 'assets/scss/typography/headings';
  @import 'assets/scss/rules/edges';

  &__title {
    @include h6;

    background-color: var(--white);
    padding-inline: 1.6rem 3.2rem;
    padding-block: 3.2rem;

    @media screen and (min-width: $tablet) {
      @include h5;
    }
  }

  &__cards {
    gap: 0.2rem;
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-template-rows: auto 1fr;

    &--no-text {
      grid-template-rows: unset;
    }

    @media screen and (max-width: calc($tablet - 1px)) {
      grid-template-rows: unset;
      grid-auto-flow: unset;

      &--force-grid-mobile {
        grid-template-rows: repeat(2, auto 1fr);
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: column;

        &.promo-block__cards--no-text {
          grid-template-rows: 1fr 1fr;
        }
      }
    }

    @media screen and (min-width: $tablet) {
      grid-auto-flow: column;
    }
  }

  .teeth {
    @include teeth;
    margin-top: -1.2rem;
  }

  .scallop {
    @include scallop;
    margin-top: -1.6rem;
  }

  .wave {
    @include wave;
    margin-top: -1.2rem;
  }
}
</style>
